<template>
  <div class="container pt-3">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <!-- <el-tooltip
        slot="button"
        class="item"
        effect="dark"
        content="Create"
        placement="top"
      >
        <router-link
          :to="{
            name: 'EducatorAddQuestion'
          }"
        >
          <el-button type="success" size="mini"
            ><i class="fa fa-plus"></i
          ></el-button>
        </router-link>
      </el-tooltip> -->
    </Heading>
    <hr />
    <AllQuestions :isAdmin="false" :isTeacher="true" :isCompany="false" />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import AllQuestions from "@/views/questions/list/components/Questions.vue";
import Breadcrumb from "@/components/Breadcrumb";
export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    AllQuestions,
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      search: {}
    };
  },
  computed: {
    ...mapGetters("user", ["getProfile"]),
    myTitle() {
      return "Questions";
    }
  },
  watch: {},

  async mounted() {},

  methods: {}
};
</script>

<style scoped></style>
