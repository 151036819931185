<template>
  <div>
    <div ref="questions-table" v-loading="!(search.isNew > -1)" class="mb-4">
      <SearchDetail
        :isAdmin="isAdmin"
        :isTeacher="isTeacher"
        :isCompany="isCompany"
        :search="search"
        @setSearchToLink="setSearchToLink"
      />
    </div>
    <!-- <hr /> -->
    <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
      <span slot="title">
        Results: <b>{{ resultsTotal }}</b>
      </span>
    </el-alert>
    <div v-if="questions.length > 0">
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
      <QuestionsTable
        :showQuestion="false"
        :showPassage="false"
        :showOptions="false"
        :showAnswer="false"
        :showExplanation="false"
        :showLabels="false"
        :questions="questions"
        :search="$route.query.search"
        :isEdit="false"
        :isAdmin="isAdmin"
      >
      </QuestionsTable>
      <div class="text-center" style="margin:15px 0">
        <el-pagination
          background
          @current-change="setPage"
          :page-size="pageSize"
          :current-page="page"
          :total="resultsTotal"
          :pager-count="9"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
    <el-empty
      v-if="resultsTotal > -1 && resultsTotal === 0"
      :image-size="200"
    ></el-empty>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import List from "@/views/questions/apis/list.js";
import SearchDetail from "@/views/questions/list/components/Search.vue";
import QuestionsTable from "@/views/questions/list/components/QuestionsTable.vue";
import Common from "@/mixins/common.js";

export default {
  metaInfo() {
    return {
      title: "Questions - " + this.CompanyName
    };
  },

  components: {
    SearchDetail,
    QuestionsTable
  },

  mixins: [Common],

  props: ["isAdmin", "isCompany", "isTeacher"],
  data() {
    return {
      search: {
        isNew: 1,
        test_types: [],
        originalSubjects: [],
        subjects: [],
        tags: [],
        difficulty: null,
        source: null,
        company_id: null,
        search: null
      },
      subjects: [],
      tags: [],
      questions: [],
      practice: null,
      resultsTotal: null,
      pageSize: 0
    };
  },
  computed: {
    helper() {
      return helper;
    },
    subjectsNow() {
      let subjects = [];
      if (this.search.isNew === 1) {
        subjects = [this.subjects[4], this.subjects[2]];
      } else {
        subjects = [
          this.subjects[0],
          this.subjects[1],
          this.subjects[2],
          this.subjects[3]
        ];
      }
      return subjects;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  async mounted() {
    // const subjects = await SAT.getSubjects();
    // this.subjects = subjects.sat_subjects;
    // await this.getTags();
    await this.setQueryStringToSearchState();
    const res = await List.getQuestions({
      page: this.page,
      per_page: 10,
      search: this.search.search
    });
    this.questions = res.data;
    this.pageSize = res.per_page;
    this.resultsTotal = res.total;
    this.initMathJaxPlugin();
  },

  methods: {
    getSource(source) {
      let NewSource = null;
      if (source) {
        if (source === "SATMocks") {
          NewSource = 1;
        } else if (source === "Official") {
          NewSource = 2;
        } else {
          NewSource = 3;
        }
      }
      return NewSource;
    },
    getCompanyId(source) {
      let company = null;
      if (source) {
        if (source === "SATMocks") {
          company = null;
        } else if (source === "Official") {
          company = null;
        } else {
          company = source;
        }
      }
      return company;
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["questions-table"]]);
      });
    },
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    setQueryStringToSearchState() {
      const query = this.$route.query;
      this.search.isNew = 1;
      this.search.subjects = [];

      Object.keys(query).forEach(key => {
        if (key === "subjects") {
          if (Array.isArray(query.subjects)) {
            this.search = {
              ...this.search,
              subjects: [...query.subjects.map(i => i * 1)]
            };
          } else {
            this.search = {
              ...this.search,
              subjects: [Number(query.subjects)]
            };
          }
        } else if (key === "isNew") {
          this.search = {
            ...this.search,
            isNew: Number(query.isNew)
          };
        } else if (key === "tags") {
          if (Array.isArray(query.tags)) {
            this.search = {
              ...this.search,
              tags: [...query.tags.map(i => i * 1)]
            };
          } else {
            this.search = {
              ...this.search,
              tags: [Number(query.tags)]
            };
          }
        } else {
          this.search = { ...this.search, [key]: query[key] };
        }
      });
      // if (this.search.subjects.length < 1) {
      //   this.subjectsNow.forEach(subject => {
      //     this.search.subjects.push(subject.id);
      //   });
      // }
      // console.log(this.search);
    },
    setSearchToLink(data) {
      // console.log(data);
      if (data.isNew > -1) {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, ...data, page: 1, subjects: [] }
        });
      } else {
        this.$router.push({
          ...this.$route,
          query: { ...this.$route.query, ...data, page: 1 }
        });
      }
    },
    setPage(page) {
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, page: page }
      });
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-tabs--border-card {
  box-shadow: none;
}
.search {
  margin: 20px 0px;
}

.questions {
  margin: 20px 0px;
}

.leftQuestionCountTip {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-question-btn {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
